import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { user } from './modules/user'
import { shop } from './modules/shop'
import { basket } from './modules/basket'

import { notifications } from './modules/notifications'

import { vouchers } from './modules/vouchers'
import { shared } from './modules/shared'

//encryption in Local Storage
// https://github.com/softvar/secure-ls
import SecureLS from 'secure-ls'
var ls = new SecureLS({ isCompression: false })

// LogRocket vuex mutations check
// https://docs.logrocket.com/docs/vuex-plugin
// https://github.com/LogRocket/logrocket-fuzzy-search-sanitizer
import LogRocket from 'logrocket'
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'

function isAllowedPage() {
	var allowed_old = [
		'/coffees',
		'/coffee-plans',
		'/coffee-pods',
		'/checkout',
		'/coffee-equipment',
		'/offers',
		'/gifts',
		'/brewing-equipment',
		'/coffee-machines',
		'/coffee-grinders',
		'/coffee-filters',
		'/bundles',
		'/christmas',
		'/on-sale'
	]
	var allowed = [
		'/coffee-plans',
		'/checkout',
    '/quiz',
		'/coffees',
    '/coffee-equipment',
    '/brewing-equipment',
		'/coffee-machines',
		'/coffee-grinders',
		'/coffee-filters',
		'/bundles',
		'/offers/metafree',
		'/offers/myfreev60',
		'/offers/metacafetiere',
		'/offers/svd23v60',
		'/offers/freshlyground',
		'/offers/google25',
		'/offers/happitycaf',
		'/offers/welcome',
		'/offers/redirect24',
		'/account/orders',
		'/account/plans',
		'/instant-coffee-upgrade',
		'/christmas',
	]
  var allowedPlpsPdps = [
    '/brewing-equipment',
		'/coffee-machines',
		'/coffee-grinders',
		'/coffee-filters',
		'/bundles',
	]

	var current_page_path = window.location.pathname
  var current_page_path_split = window.location.pathname.split('/')
	var startwithAllowed = allowed.filter((entry) => current_page_path.startsWith(entry)).length > 0
  var startwithAllowedPlpsPdps = allowedPlpsPdps.filter((entry) => current_page_path.startsWith(entry)).length > 0

	if (allowed.includes(current_page_path) || startwithAllowed || current_page_path === '/' || startwithAllowedPlpsPdps && current_page_path_split.length === 3 ) return true
	return false
}

isAllowedPage()

const privateFieldNames = [
	'password',
	'payment_method_id',
	'payment_intent_id',
	'setup_intent_id',
	'address_line_1',
	'address_line_2',
	'postcode',
	'phone_number',
]

const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(privateFieldNames)
if (process.env.NODE_ENV === 'production' && isAllowedPage()) {
	LogRocket.init('cl1xyk/pact-coffee-production', {
		network: {
			requestSanitizer,
			responseSanitizer,
		},
	})
}
import createPlugin from 'logrocket-vuex'
const logrocketPlugin = createPlugin(LogRocket, function (mutation) {
	if (mutation.type === 'notifications/decreaseCountdown') {
		return null
	}
	return mutation
})

const store = createStore({
	modules: {
		user,
		shared,
		notifications,
		shop,
		basket,
		vouchers,
	},
	plugins: [
		createPersistedState({
			key: 'pact-data',
			/*rehydrated: function(store){
        console.log('water + fire',store)
      },*/
			storage: {
				getItem: (key) => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: (key) => ls.remove(key),
			},
		}),
		logrocketPlugin,
	],
})
/*
function haha(mutation,state){
  console.log('x')
  console.log(mutation,state)
}
store.subscribe(haha)
DEBUG */
export default store
